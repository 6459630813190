<template>
    <div class="featured-list-body">
        <h1 class="featured-title">{{ $t('headline.featured') }}</h1>
        <div class="featured-list characteristic-li">
            <ul>
                <li :key='key' v-for="(featured,key) in featuredList">
                    <img class="featured-img" @click="onLink(featured)" :src="featured.coverImage" v-lazy="featured.coverImage"/>
                    <div class="featured-text">
                        <p class="text-row">{{ featured.coverTitle }}</p>
                        <p class="text-title">{{ featured.keywords }}</p>
                        <p class="text-row">{{ featured.coverSubtitle }}</p>
                    </div>
                </li>
            </ul>
            <div class="featured-loading" v-if="loadingVisible"></div>
        </div>
    </div>
</template>
<script>
import FeaturedList from './list'
export default FeaturedList
</script>
<style lang="less">
@import "list";
</style>
